
/* eslint-disable no-param-reassign */
import { Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import Component from 'vue-class-component';
import PriceFilter from '@/modules/common/filters/price.filter';
import Demand from '@/modules/common/components/ui-kit/demand/index.vue';
import ProviderCard from '@/modules/common/components/ui-kit/provider-card.vue';
import Currency from '@/modules/common/components/currency.vue';
import AllChannelCell from '@/modules/di-lite/components/all-channels/all-channel-cell.vue';
import CIUpgradeCard from '@/modules/di-lite/components/all-channels/ci-upgrade-card.vue';
import DiLiteAllChannelsService, { DiLiteAllChannelsServiceS } from '@/modules/di-lite/di-lite-all-channels.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import type Day from '@/modules/common/types/day.type';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';

@Component({
    components: {
        Demand,
        ProviderCard,
        CIUpgradeCard,
        AllChannelCell,
        Currency,
    },
    filters: {
        PriceFilter,
    },
})
export default class AllChannels extends Vue {
    @Inject(HelperServiceS)
    helperService!: HelperService;

    @Inject(DiLiteAllChannelsServiceS)
    private diLiteAllChannelsService!: DiLiteAllChannelsService;

    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    readonly brand = 'bcom';

    getDemandValue(index: number) {
        const { demandData } = this.diLiteAllChannelsService;

        return demandData.length ? demandData[index] : null;
    }

    diff(day: string) {
        const maxDiff = this.diLiteAllChannelsService.getMaxDiff(day);
        return maxDiff ? Math.round(maxDiff.diff) : null;
    }

    absDiff(day: string) {
        const maxDiff = this.diLiteAllChannelsService.getMaxDiff(day);
        return maxDiff ? Math.round(maxDiff.diffRatio) : null;
    }

    formatPercent(value: number | string, maxFixed: number = 2, minFixed: number = 0) {
        return Number(value).toLocaleString('en-US', { maximumFractionDigits: maxFixed, minimumFractionDigits: minFixed });
    }

    handleProviderClick(provider: string) {
        if (!this.diLiteAllChannelsService.isBrandAvailable) {
            this.diLiteAllChannelsService.provider = provider;
        }
    }

    isProviderSelected(provider: string) {
        return this.diLiteAllChannelsService.provider === provider;
    }

    getDateByDay(day: number) {
        return this.diLiteAllChannelsService.getDateByDay(day as Day);
    }

    get isBrandAvailable() {
        return this.diLiteAllChannelsService.isBrandAvailable;
    }

    get isDocumentAvailable() {
        return (!!this.diLiteAllChannelsService.data || this.isLoading) && this.providers.length;
    }

    get isLoading() {
        const state = !this.diLiteAllChannelsService.data || this.diLiteAllChannelsService.isLoading;

        if (!state) {
            this.scrollToTodayRow();
        }

        return state;
    }

    get providers() {
        if (this.isLoading) {
            return Array.from({ length: 5 }) as string[];
        }

        return this.diLiteAllChannelsService.shownProviders;
    }

    isDayActive(day: Day) {
        return this.documentFiltersService.isCurrentDay(day);
    }

    scrollToTodayRow() {
        const { table, header } = this.$refs as Record<string, HTMLElement>;
        if (!table) return;

        const activeEl = table.querySelector('[data-active]') as HTMLElement;

        if (!activeEl) return;

        table.scrollTop = activeEl.offsetTop - header.offsetHeight;
    }
}
