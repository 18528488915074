

import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ConfigService, { ConfigServiceS } from '@/modules/config/config.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';

@Component
export default class DIUpgradeCard extends Vue {
    @Inject(ConfigServiceS) private configService!: ConfigService;
    @Inject(UserServiceS) private userService!: UserService;
}
