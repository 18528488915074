
import { Inject } from 'inversify-props';
import { Prop, Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import PriceFilter from '@/modules/common/filters/price.filter';
import DiLiteAllChannelsService, { DiLiteAllChannelsServiceS } from '@/modules/di-lite/di-lite-all-channels.service';
import type { ProviderData } from '@/modules/di-lite/interfaces/provider-data.interface';
import Currency from '@/modules/common/components/currency.vue';
import DayBetterIndicators from '@/modules/rates/components/table/day-better-indicators.vue';
import DayIndicatorTooltip from '@/modules/rates/components/table/day-indicator-tooltip.vue';

const BOOKING_BASIC_ICON = require('@/modules/common/assets/booking-basic.svg');

@Component({
    components: {
        Currency,
        DayBetterIndicators,
        DayIndicatorTooltip,
    },
    filters: { PriceFilter },
})
export default class AllChannelCell extends Vue {
    @Inject(DiLiteAllChannelsServiceS) allChannelsService!: DiLiteAllChannelsService;

    @Prop({ required: false, default: false })
    private isBrand!: boolean;

    @Prop({
        type: [],
        required: true,
    })
    public data!: ProviderData;

    @Prop({ type: String })
    private day!: string;

    get bookingBasicIcon() {
        return BOOKING_BASIC_ICON;
    }

    get priceIndicator() {
        return {
            losRestriction: this.data.losRestriction,
        };
    }

    get referencePrice() {
        return this.allChannelsService
            .getReferencePrice(this.day);
    }

    get priceColorClass() {
        if (this.isBrand) return {};
        const { price } = this.data;
        const { referencePrice } = this;

        if (!referencePrice) return {};
        if (!price) return {};

        const lowReferenceError = referencePrice * (1 - 0.015);
        const highReferenceError = referencePrice * (1 + 0.015);

        const isEqualReferencePrice = price > lowReferenceError && price < highReferenceError;

        return {
            'all-channels-cell--green': isEqualReferencePrice,
            'all-channels-cell--yellow': referencePrice < price && !isEqualReferencePrice,
            'all-channels-cell--red': referencePrice > price && !isEqualReferencePrice,
        };
    }
}
